.app__navbar {
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 8px 32px 0 rgba(82, 90, 205, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  .app__navbar-desktop {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

    .app__navbar-img {
      a {
        img {
          height: 60px;
        }
      }
    }

    .app__navbar-text {
      font-size: 21px;
      font-weight: 600;

      nav {
        display: flex;

        li {
          list-style: none;
          margin-left: 50px;

          .nav-names {
            color: black;
            text-decoration: none;
            transition: all 0.2s ease-in-out;

            &.active {
              color: #2232e3;
            }

            &:hover {
              color: #2232e3;
            }
          }

          .nav-dot {
            height: 7px;
            width: 7px;
            border-radius: 50%;
            margin-bottom: 5px;
            max-width: 100%;
            margin: 0 auto;
          }

          &:hover {
            .nav-dot {
              background-color: #2232e3;
            }
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

.app__mob-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;

  .app__navbar-img-mob {
    img {
      height: 50px;
    }
  }

  .app__navbar-mobile {
    background-color: #2210e8;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 70%;
      height: 70%;
      color: #ffffff;
    }

    div {
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100vh;
      right: 0;
      z-index: 10;
      width: 60%;
      display: flex;
      flex-direction: column;
      background-color: #d6e1ee;

      svg {
        width: 35px;
        height: 35px;
        position: fixed;
        right: 38px;
        top: 30px;
        color: #111;
      }

      ul {
        list-style: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        margin-top: 60px;

        li {
          margin: 40px;

          .nav-names-mob {
            text-decoration: none;
            color: #111111;
            transition: all 0.2s ease-in-out;
            font-size: 22px;
            font-weight: 500;

            &.active {
              color: #2232e3;
            }

            &:hover {
              color: #2232e3;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 800px) {
    display: none;
  }
}
