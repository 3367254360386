.app__home-logos {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  user-select: none;
  p {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100%));
    }
  }

  .app__brands-cards {
    overflow: hidden;
    display: flex;
    gap: 10px;

    .app__brand-section {
      animation: 15s scroll infinite linear;
      display: flex;
      width: calc(200px * 40);
      gap: 10px;

      .brand-card {
        background-color: #f0f8ff;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        scroll-behavior: smooth;

        img {
          width: 200px;
          height: 200px;
          object-fit: contain;
        }

        p {
          font-size: 22px;
          margin-bottom: 0;
          padding-top: 10px;
        }

        @media screen and (max-width: 800px) {
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    p {
      font-size: 27px;
    }
  }
}
