.app__services {
  padding-top: 40px;
  padding-bottom: 80px;
  max-width: 90%;
  margin: 0 auto;

  .app__services-head {
    margin-bottom: 80px;

    h2 {
      font-size: 70px;
      color: #000000;
      text-align: center;
    }

    p {
      text-align: center;
      font-weight: 700;
      margin-top: 10px;
      font-size: 20px;
    }

    @media screen and (max-width: "688px") {
      h2 {
        font-size: 30px;
      }
    }
  }

  .app__services-card {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .services-card {
      display: flex;
      background-color: #031448;
      color: #fff;
      height: 350px;

      .card-img {
        display: flex;
        flex: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-position: top;
        }
      }

      .card-p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 50%;

        h3 {
          margin-bottom: 20px;
          font-size: 30px;
        }

        li {
          font-size: 18px;
          margin-top: 10px;
          font-weight: 500;
        }
      }

      .warranty-txt {
        display: flex;
        flex: 50%;
        flex-direction: column;
      }

      .warranty-img {
        display: flex;

        flex: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-position: top;
        }
      }

      @media screen and (max-width: "688px") {
        flex-direction: column;
        height: auto;

        .card-p {
          padding: 20px 10px;

          h3 {
            font-size: 21px;
          }

          p {
            font-size: 17px;
          }
        }
      }
    }

    @media screen and (max-width: "688px") {
      .services-card-rev {
        flex-direction: column-reverse;
      }
    }
  }
}
