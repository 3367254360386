.app__about {
  .coverimg {
    background-image: url("../../assets/about.png");
    height: 410px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
  }

  .app__about-main {
    text-align: center;

    .since-h {
      margin: 0 auto;
      margin-top: 35px;
      font-size: 30px;
      max-width: 90%;
    }

    .sinceh2 {
      margin-top: 80px;
      font-weight: 500;
      font-size: 25px;
    }

    .app__about-content {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      gap: 70px;

      .app__about-cards {
        background-color: #031448;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: default;
        user-select: none;

        p {
          font-size: 20px;
          margin-top: 5px;
          font-weight: 600;
        }

        @media screen and (max-width: "650px") {
          p {
            font-size: 17px;
          }
        }
      }

      .cards-2 {
        padding: 10px 27px;
      }

      @media screen and (max-width: "450px") {
        flex-wrap: wrap;
        gap: 30px;
      }
    }

    .about-brand {
      margin-bottom: 80px;
    }

    .about-values {
      max-width: 90%;
      margin: 0 auto;
      margin-bottom: 80px;

      h2 {
        font-size: 30px;
        margin-bottom: 50px;
      }

      .values-boxes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;

        .value-box {
          background-color: #ffdb58;
          width: max-content;
          padding: 20px 10px;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 15px;

          h3 {
            font-size: 23px;
          }

          p {
            font-size: 19px;
          }
        }
      }

      @media screen and (max-width: "522px") {
        div {
          flex-wrap: wrap;
          gap: 20px;
        }
      }
    }

    .about-ourteam {
      max-width: 90%;
      margin: 0 auto;
      margin-bottom: 80px;

      h2 {
        font-size: 30px;
        margin-bottom: 50px;
      }
    }
  }

  @media screen and (max-width: "1145px") {
    .coverimg {
      height: 300px;
    }
  }

  @media screen and (max-width: "860px") {
    .coverimg {
      height: 260px;
    }
  }

  @media screen and (max-width: "756px") {
    .coverimg {
      height: 220px;
    }
  }

  @media screen and (max-width: "641px") {
    .coverimg {
      height: 180px;
    }
  }

  @media screen and (max-width: "525px") {
    .coverimg {
      height: 120px;
    }
  }

  @media screen and (max-width: "800px") {
    .app__about-main {
      .since-h {
        font-size: 22px;
      }

      .about-ourteam {
        font-size: 22px;
      }

      .about-values {
        h2 {
          font-size: 22px;
        }

        div {
          p {
            font-size: 19px;
          }
        }
      }
      .about-ourteam {
        h2 {
          font-size: 22px;
        }
      }
    }
  }
}
