.app__footer {
  background-color: #031448;
  color: white;
  .app__footer-line {
    height: 1px;
    width: 100%;
    background-color: #e3e6f1;
    margin-bottom: 50px;
  }

  .app__footer-line-2 {
    height: 0.5px;
    width: 100%;
    background-color: #e3e6f1;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .app__footer-content {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .social {
      display: flex;
      flex-direction: column;
      gap: 20px;

      a {
        font-size: 25px;
        cursor: pointer;
        color: blue;
        font-weight: 600;

        img {
          height: 40px;
        }
      }
    }

    .address {
      width: 25%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      p {
        line-height: 30px;
        font-size: 18px;
      }
    }

    .contact {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      p {
        font-size: 18px;
      }
    }
  }

  .footer-copyright {
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;

    p {
      text-align: center;
    }
  }

  @media screen and (max-width: 800px) {
    .app__footer-content {
      flex-direction: column-reverse;
      align-items: center;
      gap: 20px;
      h2 {
        font-size: 20px;
      }

      .address {
        width: 95%;
        text-align: center;
        align-items: center;

        h2 {
          text-align: center;
        }

        p {
          line-height: 30px;
          font-size: 18px;
        }
      }

      .contact {
        width: 95%;
        text-align: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
