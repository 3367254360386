.app__home-filter {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 70px;

  .filter-header {
    background-color: #031448;
    color: #fff;
    padding: 20px 10px;
    border-radius: 10px;
  }

  p {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .filter-budgets {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

    button {
      background: none;
      border: none;
      cursor: pointer;
      background-color: #f0f8ff;
      padding: 10px 25px;
      border-radius: 25px;
      font-size: 20px;
      font-weight: 500;
      font-size: 20px;

      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #e7ffe9;
        border: none;
      }
    }

    .item-active {
      background-color: #e7ffe9;
      border: none;
    }

    @media screen and (max-width: "680px") {
      justify-content: center;
    }
  }

  .filter-cards {
    display: flex;
    gap: 20px;
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: space-between;

    .filter-cards-card {
      width: 350px;
      height: fit-content;
      background-color: #e7ffe9;
      border-radius: 10px;
      margin-top: 20px;

      img {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 2%;
      }

      p {
        font-size: 20px;
        margin-left: 10px;
        font-weight: 500;
        margin-bottom: 25px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    p {
      font-size: 27px;
    }

    .filter-budgets {
      button {
        font-size: 17px;
      }
    }

    .filter-cards {
      justify-content: center;
      gap: 30px;
    }
  }
}
