.app__home {
  width: 100%;

  .app__home-hero {
    background-image: url("../../assets/jeep.jpg");
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 30%;
    background-color: rgba(2, 1, 35, 0.4);
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      gap: 20px;
      border-radius: 10px;
      justify-content: center;
      margin-top: -100px;
    }

    p {
      font-size: 22px;
      border-radius: 20px;
      padding: 5px 10px;
      font-weight: 500;
      color: #fff;
    }

    input {
      width: 300px;
      height: 50px;
      font-size: 22px;
      text-align: center;
      border-radius: 30px;
      border: 2px solid #111;
    }

    input:focus {
      outline: none;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      background-color: #031448;
      color: #fff;
      padding: 10px 25px;
      border-radius: 25px;
      font-size: 20px;
    }

    @media screen and (max-width: "800px") {
      .app__home-search-main {
        flex-direction: column;
      }

      input {
        font-size: 19px;
      }
    }

    @media screen and (max-width: "745px") {
      height: 450px;
    }

    @media screen and (max-width: "745px") {
      height: 350px;

      div {
        margin-top: -60px;
      }
    }

    @media screen and (max-width: "470px") {
      height: 300px;
    }
  }

  .app__home-search {
    max-width: 90%;
    margin: auto;
    padding-top: 40px;

    h3 {
      margin-bottom: 25px;
      font-size: 22px;
    }

    #no-results {
      color: rgb(194, 19, 19);
      margin-top: -25px;
      margin-bottom: 0;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      background-color: #031448;
      color: #fff;
      padding: 10px 25px;
      border-radius: 25px;
      font-size: 20px;
      margin-top: 25px;
      display: none;
    }

    .search-cards {
      display: flex;
      gap: 20px;
      margin-top: 50px;
      flex-wrap: wrap;

      .search-cards-card {
        width: 350px;
        height: fit-content;
        background-color: #e7eeff;
        border-radius: 10px;

        img {
          width: 100%;
          margin-bottom: 20px;
          border-radius: 2%;
        }

        p {
          font-size: 20px;
          margin-left: 10px;
          font-weight: 500;
          margin-bottom: 25px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      .search-cards {
        justify-content: center;
        gap: 30px;
      }
    }
  }

  .app__home-featured {
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 70px;

    .feature-cards {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-top: 50px;
      flex-wrap: wrap;

      .feature-cards-card {
        width: 350px;
        height: fit-content;
        background-color: #e7eeff;
        border-radius: 10px;

        img {
          width: 100%;
          margin-bottom: 20px;
          border-radius: 2%;
        }

        p {
          font-size: 20px;
          margin-left: 10px;
          font-weight: 500;
          margin-bottom: 25px;
        }
      }
    }

    @media screen and (max-width: 800px) {
      h1 {
        font-size: 27px;
      }

      .feature-cards {
        justify-content: center;
        gap: 30px;
      }
    }
  }
}
