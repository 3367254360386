.app__notfound {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  height: 70vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 100px;
    color: #0f15d1;
  }

  p {
    font-size: 18px;
  }

  a {
    color: #0f15d1;
    font-weight: 500;
  }
}
