:root {
  --font-base: "Noto Sans JP", sans-serif;

  --primary-color: #edf2f8;
  --btn-color: #1321bc;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Noto Sans JP", sans-serif;
  scroll-behavior: smooth;
}
