.search-cards {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  flex-wrap: wrap;

  .search-cards-card {
    width: 350px;
    height: fit-content;
    background-color: #e7eeff;
    border-radius: 10px;

    img {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 2%;
    }

    p {
      font-size: 20px;
      margin-left: 10px;
      font-weight: 500;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 800px) {
    justify-content: center;
    gap: 30px;
  }
}
