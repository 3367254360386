.app__contact {
  .coverimg {
    background-image: url("../../assets/contact.png");
    height: 420px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;

    @media screen and (max-width: "1145px") {
      height: 300px;
    }

    @media screen and (max-width: "860px") {
      height: 260px;
    }

    @media screen and (max-width: "756px") {
      height: 220px;
    }

    @media screen and (max-width: "641px") {
      height: 180px;
    }

    @media screen and (max-width: "525px") {
      height: 120px;
    }
  }

  .contact-content {
    max-width: 90%;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .content-left {
      width: 30%;
      background-color: #031448;
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 22px 10px;
      justify-content: space-between;

      .p-icon {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 10px;
        font-weight: 500;

        .email-ico {
          font-size: 30px;
        }

        .ico {
          font-size: 30px;
        }
      }

      .p-one {
        letter-spacing: 1px;
      }

      p {
        font-size: 18px;
      }

      .social {
        display: flex;
        flex-direction: column;
        gap: 20px;
        img {
          height: 40px;
          margin-right: 20px;
        }
      }
    }

    .content-right {
      width: 70%;
      background-color: #f0f8ff;
      padding: 22px 30px;
      display: flex;
      flex-direction: column;
      //   justify-content: space-between;
      gap: 50px;

      h3 {
        text-align: center;
        font-size: 25px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        input,
        textarea {
          font-size: 18px;
          padding: 10px 5px;
          border: 2px solid #031448;
          resize: none;

          &:focus {
            outline: none;
          }
        }

        button {
          background: none;
          border: none;
          cursor: pointer;
          background-color: #031448;
          color: #fff;
          padding: 22px 25px;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    @media screen and (max-width: "1141px") {
      max-width: 90%;
    }

    @media screen and (max-width: "650px") {
      flex-direction: column-reverse;

      .content-left {
        width: 100%;
      }

      .content-right {
        width: 100%;
      }
    }
  }
  .contact-map {
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 80px;

    @media screen and (max-width: "1141px") {
      max-width: 90%;
    }
  }
}
